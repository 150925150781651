@import "src/constants";

.header {
  @include site-block;
  position: absolute;
  z-index: $header-z-index;
  background: transparent;
  height: 80px;
  left: 50%;
  translate: -50%;
  top: 0;
  display: flex;
  align-items: center;

  .header-logo-container {
    display: inline-flex;
    margin-bottom: 0;
  }

  .header-logo {
    height: 28px;
    width: auto;
  }

  .header-content {
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin-left: 8%;
    display: none;

    @include wide-desktop() {
      display: flex;
    }

    .header-link {
      text-decoration: none;
      color: $text-color-light;
      font-size: 15px;
      font-weight: 600;
      padding: 10px;
      transition: box-shadow .2s;


      &:hover {
        box-shadow: 0 2px 0 $text-color-light;
      }
    }
  }
}
