@import "src/constants";

.content {
  flex: 1;
  display: flex;
  flex-direction: column;

  .section-header {
    margin: 0 0 15px;
    color: #3DB9FF;
  }

  .section-subheader {
    margin: 0 0 20px;
  }

  .section-text {
    margin: 0 0 15px;
  }

  @include desktop {
    .section-header {
      margin: 0 0 20px;
    }

    .section-subheader {
      margin: 0 0 25px;
    }

    .section-text {
      margin: 0 0 20px;
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0 17px;
    li {
      position: relative;
      &:before {
        content: '';
        position: absolute;
        width: 7px;
        height: 7px;
        background: #8043F1;
        border-radius: 100%;
        margin: 0 -17px;
        transform: translateY(-50%);
        top: 50%;
      }
    }
  }

}