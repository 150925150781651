@import "src/constants";

.solutions-section {
  padding: 10vw 0 2vw;
  background: $main-bg-color;
  @include skew-section(9);

  .container {
    @include site-block;
    min-height: 600px;
    overflow: hidden;
  }

  .section-subheader {
    max-width: 640px;
  }

  .section-text {
    max-width: 806px;
  }

  .solutions-grid {
    display: flex;
    flex-direction: column;
    margin: 40px 0;
    row-gap: 40px;

    @include desktop {
      margin-top: 60px;
      row-gap: 60px;
    }

    .grid-row {
      display: flex;
      flex-direction: column-reverse;
      row-gap: 40px;

      @include desktop {
        flex-direction: row;
        row-gap: 0;

        .item-text-container {
          max-width: 440px;
        }

        .grid-item {
          justify-content: flex-end;
        }

        &:nth-child(even) {
          flex-direction: row-reverse;
          .grid-item {
            justify-content: flex-start;
          }
        }
      }
    }

    .grid-item {
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;


      &.wide-mobile-img {
        @include mobile {
          justify-content: flex-start;
          img {
            width: 530px;
            margin-left: -35px;
          }
        }
      }

      img {
        width: 100%;
        height: auto;
        @include desktop {
          width: 105%;
        }
      }
    }
  }
}
