@import "src/constants";

.footer {
  background-color: $section-bg-dark-color;
  z-index: $footer-z-index;
  height: 320px;
  width: 100%;

  @include wide-desktop() {
    height: 206px;
  }

  .footer-content {
    @include site-block;
    max-width: 1200px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

    @include wide-desktop() {
      flex-direction: row;
    }

    .footer-logo {
      height: 43px;
      width: auto;
      display: none;
      margin-right: 80px;

      @include wide-desktop() {
        display: initial;
      }
    }

    .footer-content-container {
      display: flex;
      justify-content: center;
      flex-direction: column-reverse;
      width: 100%;
      height: 100%;
      gap: 50px;

      @include wide-desktop() {
        flex-direction: column;
        gap: 25px 50px;
      }

      .footer-img-container {
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: .4;
        width: 100%;
        flex-wrap: wrap;
        gap: 25px 50px;

        .footer-img {
          height: 26px;
          width: auto;
        }

        @include wide-desktop() {
          flex-wrap: nowrap;
          justify-content: space-between;
          gap: unset;

          .footer-img {
            height: 32px;
          }
        }
      }

      .footer-info {
        display: flex;
        justify-content: space-around;
        font-size: $text-mobile-small-font-size;
        width: 100%;

        @include wide-desktop() {
          font-size: $text-desktop-small-font-size;
        }

        .mobile-footer-logo {
          display: initial;
          height: 42px;
          width: auto;

          @include wide-desktop() {
            display: none;
          }
        }

        .footer-info-container {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-direction: column;
          gap: 15px;

          @include wide-desktop() {
            flex-direction: row;
            width: 100%;
          }
        }
      }

      .footer-links-block > a {
        text-decoration: none;
        color: $text-color-dark;
        font-size: $text-mobile-small-font-size;
        padding: 5px;
        transition: .2s;

        &:hover {
          color: $text-color-light;
        }

        @include wide-desktop() {
          font-size: $text-desktop-small-font-size;
        }
      }
    }
  }
}
