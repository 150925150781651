@import "src/constants";

.company-section {
  padding: 10vw 0 5vw;
  position: relative;
  @include skew-section(6);
  background: linear-gradient(86.8deg, #8043F0 10.47%, #65B1FF 110.6%);
  .section-header, .section-subheader, .section-text {
    color: #ffffff;
  }

  h2 {
    max-width: 800px;
  }

  p {
    max-width: 527px;
    width: 100%;
  }

  @include desktop {
    .texts {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      column-gap: 20px;
      p {
        max-width: 430px;
      }
    }
  }

  .container {
    @include site-block;
  }

  .bo-logo-mobile {
    display: block;
    position: absolute;
    right: 16px;
    top: 25px;

    @include desktop {
      display: none;
    }
  }

  .bo-logo-desktop {
    display: none;
    position: absolute;
    right: 0;
    top: 4vw;

    @include desktop {
      display: block;
    }
  }
}
