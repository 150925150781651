@import 'src/constants';

.common-page {
  background-color: #E4E4E4;
  .header-block {
    background: linear-gradient(89.66deg, #65B1FF 6.14%, #8043F0 98.97%);
    @include skew-section(10);
    &:before {
      min-height: 400px;
    }
    height: 60px;

    .header {
      @include common-page-block;
      max-width: unset;

      .header-link {
        margin-bottom: 0;
      }
    }
  }

  .main-header-block {
    background-color: $common-bg-color-1;
    @include skew-section(9);
    padding: 10vw 0 2vw;
    &:before {
      min-height: 400px;
    }

    h1 {
      margin: 0;
      @include common-page-block;
      @include desktop {
        font-size: 56px;
        padding-right: 0;
      }
    }
  }

  .section {
    padding: 10vw 0 2vw;
  }

  .container {
    @include common-page-block;
    width: 100%;
    h5 {
      color: $accent-color-1;
      margin: 0 0 -20px;
      text-transform: uppercase;
    }

    h2 {
      color: $title-color;
      margin: 40px 0 20px;
      max-width: 620px;

      @include desktop {
        font-size: 34px;
      }
    }
  }

  .numbered-list, ul {
    margin: 0;
    padding: 0;
  }

  .lettered-list {
    margin: 20px 0 0;
    padding: 0;
    list-style-type: lower-alpha;

    li {
      margin-left: 25px;
      @include desktop {
        margin-left: 35px;
      }
    }
  }

  li, p, a {
    font-size: $text-mobile-regular-font-size;
    line-height: $text-mobile-regular-line-height;
    margin-bottom: 20px;

    @include desktop {
      font-size: $text-desktop-regular-font-size;
      line-height: $text-desktop-regular-line-height;
    }
  }

  a {
    color: $accent-color-2;
    font-weight: 600;
  }

  li {
    margin-left: 20px;
    @include desktop {
      margin-left: 30px;
    }
  }
}
