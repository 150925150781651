@import "src/constants";

.contact-form-section {
  padding: 10vw 0 0;
  margin-bottom: 100px;
  background: $main-bg-color;
  .container {
    @include site-block;
  }

  h2 {
    max-width: 550px;
  }

  p {
    max-width: 700px;
    width: 100%;
  }

  .form-subheader {
    margin-bottom: 25px;
    @include desktop {
      margin-bottom: 40px;
    }
  }

  .form-container {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    margin-bottom: 40px;
    @include desktop {
      flex-direction: row;
      row-gap: 20px;
      column-gap: 25px;
      margin-bottom: 31px;
    }
  }

  .inputs-block {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    width: 100%;
    align-items: flex-end;

    @include desktop {
      flex-direction: column;
      column-gap: 25px;
    }
  }

  .input-container {
    width: 100%;
    display: flex;
    flex-direction: column;

    &.textarea {
      max-width: 100%;
      min-width: 100%;
    }
  }

  .input {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: $text-mobile-regular-font-size;
    line-height: $text-mobile-regular-line-height;
    height: 44px;
    padding: 12px 20px 14px;
    border: none;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.55);

    &::placeholder {
      color: #45454573;
    }


    &:focus {
      border: 1px solid #B39EE8;
      outline: 1px solid #B39EE8;
    }

    &.textarea {
      min-height: 90px;
      max-width: 100%;
      min-width: 100%;
      resize: none;
      overflow: hidden;
      border: 1px solid transparent;
      @include desktop {
        min-height: 115px;
      }
      font-family: 'OpenSans', 'Open Sans', sans-serif;
      font-weight: 400;
    }

    &.error {
      border: 1px solid #E523FA;
    }

    @include desktop {
      font-size: 15px;
      line-height: $text-desktop-regular-line-height;
      height: 50px;
      padding: 10px 30px 12px;
    }
  }

  .error-text {
    color: #D232E2;
    font-size: 12px;
    line-height: 18px;
    margin: 0 0 6px;

    &.recaptcha {
      text-align: right;
      margin-bottom: -10px;
    }
  }

  .google-recaptha-error {
    outline: 1px solid #D232E2;
    border-radius: 4px;
  }

  .button-holder {
    display: flex;
    justify-content: center;
    @include desktop {
      justify-content: flex-end;
    }
  }

  .form-submitted-message {
    min-height: 530px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;

    @include desktop {
      min-height: 677px;
    }

    h2 {
      max-width: 100%;
    }

    p {
      max-width: 710px;
    }

    .desktop-image {
      display: none;
      @include desktop {
        display: block;
      }
    }

    .mobile-image {
      display: block;
      @include desktop {
        display: none;
      }
    }

    .mobile-ellipse1 {
      position: absolute;
      width: 319.06px;
      height: 263.71px;
      left: -98px;
      top: 93.9px;
    }

    .mobile-ellipse2 {
      position: absolute;
      width: 325.72px;
      height: 295.75px;
      left: 123.2px;
      top: 296.73px;
    }

    .desktop-ellipse1 {
      position: absolute;
      width: 611px;
      height: 505px;
      left: 380px;
      top: 115.57px;
    }
    .desktop-ellipse2 {
      position: absolute;
      width: 406px;
      height: 335px;
      left: 860.1px;
      top: 304.12px;
    }
  }
}