@import "src/constants";

.services-section {
  @include skew-section(8);
  background-color: $section-bg-dark-color;
  padding: 10vw 0 2vw;
  color: $text-color-light;

  .slide-item {
    margin: 0 15px;

    @include desktop {
      margin: 0 28px;
    }
  }

  .section-subheader {
    color: $text-color-light;
    max-width: 640px;
  }

  .section-text {
    max-width: 806px;
  }

  .container {
    @include site-block;
    padding-top: 10px !important;
    overflow: hidden;
  }

  .slider {
    margin: 25px 0 80px;
  }

  .arrows-container {
    @include site-block;
    margin-top: 35px;
    display: flex;

    justify-content: center;
    @include desktop {
      margin-top: 25px;
      justify-content: flex-end;
    }
  }

  .arrows {
    display: inline-flex;
    flex-direction: row;
    column-gap: 20px;
    @include desktop {
      column-gap: 37px;
    }
  }

  .arrow-img {
    cursor: pointer;
    &.next {
      rotate: 180deg;
    }
  }

  .arrow-container {
    position: relative;
  }

  .progress {
    position: absolute;
    translate: 8px -2px;
  }
}
