@import "src/constants";

.button {
  cursor: pointer;
  padding: 4px 31px;
  height: 33px;
  background: $button-bg-color;
  border-radius: 50px;
  color: rgba(255,255,255,.9);
  border: none;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: $button-bg-color-hover;
  }
  &:active {
    background: $button-bg-color-active;
  }
  &:focus {
    outline: 1px solid #B39EE8;
  };

  &.gradient {
    background: $button-bg-gradient;
    &:hover {
      background: $button-bg-gradient-hover;
    }
    &:active {
      background: $button-bg-gradient-active;
    }
  }

  &.outline {
    background-color: transparent;
    border: 1px solid $button-bg-color;
    color: $button-bg-color;
    transition: color .2s ease-out, border .2s ease-out;

    &:hover {
      border: 1px solid $button-bg-color-hover;
      color: $button-bg-color-hover;
    }

    &:active {
      border: 1px solid $button-bg-color-active;
      color: $button-bg-color-active;
    }
  }

  @include desktop {
    padding: 4px 42px;
    height: 40px;
  }
}
