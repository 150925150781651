@import "src/constants";

.benefits-grid {
  margin: 40px -18px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @include desktop {
    margin: 60px 0;
    column-gap: 30px;
    justify-content: space-between;
  }
}

.benefits-grid-item {
  width: 100%;
  padding: 25px 15px;

  .benefits-grid-text {
    font-size: $text-desktop-small-font-size;
    line-height: $text-desktop-small-line-height;
  }

  .benefits-grid-image {
    height: 50px;
    @include desktop {
      height: 72px;
    }
  }

  .benefits-grid-header {
    @include desktop {
      margin: 17px 0 15px;
    }
  }

  .benefits-grid-text {
    margin: 0;
    padding: 0 0 10px;

    @include mobile {
      padding-left: 70px;
    }
  }

  .head {
    background-color: $main-bg-color;
    width: 100%;
  }

  @include mobile {
    .head {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;

      .benefits-grid-header {
        margin: 0 auto 0 20px;
      }
    }

    .benefits-grid-text {
      display: none;
    }
  }

  .arrow {
     transition: .2s ease-out;
   }

  @include desktop {
    max-width: 250px;
    .arrow {
      display: none;
      transition: .2s ease-out;
    }
  }

  @include mobile {
    &.active {
      background-color: #edeefd;
      .head {
        background-color: #edeefd;
      }
      .benefits-grid-text {
        display: block;
        animation: open-animation .2s ease-out;
      }

      .benefits-grid-header {
        color: $accent-color-2;
      }

      .arrow {
        stroke: $accent-color-2;
        transform: rotate(180deg);
      }
    }
  }
}

@keyframes open-animation {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
}
