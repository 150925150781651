@import 'src/constants';

@font-face {
  font-family: "Manrope";
  src: local("Manrope"),
  url("./fonts/Manrope-ExtraBold.ttf") format("truetype");
  font-weight: 800;
}
@font-face {
  font-family: "OpenSans";
  src: local("OpenSans"),
  url("./fonts/OpenSans-Regular.ttf") format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "OpenSans";
  src: local("OpenSans"),
  url("./fonts/OpenSans-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $main-bg-color;
  * {
    box-sizing: border-box;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
