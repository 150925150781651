@import "src/constants";

.top-section {
  height: 650px;

  background-color: $section-bg-dark-color;
  @include skew-section(10);
  @include mobile() {
    height: 500px;
  }

  .top-section-background {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    mask: radial-gradient(circle at 75% 50%, #000, transparent 100%);
    background:url("img/background.jpg") no-repeat top $section-bg-dark-color;
    background-size: initial;
    z-index: -1;

    @include mobile() {
      background:url("img/background-mobile.png") no-repeat center right $section-bg-dark-color;
      background-size: auto 100%;
      mask: none;
    }
  }

  .link-holder {
    margin-right: auto;
  }

  h1, p {
    color: $text-color-light !important;
  }

  padding-bottom: 100px;

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 9px;
    background: linear-gradient(89.79deg, #65B1FF 6.22%, #8043F0 99.25%);
    bottom: -3px;
    z-index: -1;
    transform-origin: right bottom;
    transform: skewY($skew-deg);
  }

  .container {
    @include site-block;
    display: flex;
    flex-direction: column;

    h1 {
      margin: 112px 0 20px;
      @include desktop {
        margin: 150px 0 30px;
      }

      max-width: 645px;
    }

    p {
      margin: 0 0 50px;
      max-width: 527px;
    }

    .link {
      margin-bottom: 125px;
      @include desktop {
        margin-bottom: 170px;
      }
      margin-right: auto;
    }
  }
}
