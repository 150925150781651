$MOBILE_ONLY: 599px;
$TABLET_PORTRAIT: 600px;
$TABLET_LANDSCAPE: 900px;
$DESKTOP_M: 1200px;
$DESKTOP_L: 1800px;

$text-desktop-regular-font-size: 18px;
$text-desktop-regular-line-height: 28px;
$text-desktop-small-font-size: 15px;
$text-desktop-small-line-height: 24px;
$button-desktop-font-size: 18px;
$button-desktop-line-height: 24px;

$text-mobile-regular-font-size: 13px;
$text-mobile-regular-line-height: 20px;
$text-mobile-small-font-size: 13px;
$text-mobile-small-line-height: 19px;
$button-mobile-font-size: 15px;
$button-mobile-line-height: 24px;

$main-bg-color: #e7e8fd;
$button-bg-color: #9423DA;
$button-bg-color-hover: #8B1AD1;
$button-bg-color-active: #7F08C8;

$button-bg-gradient: linear-gradient(90.19deg, #65B1FF -0.61%, #8043F1 99.46%);
$button-bg-gradient-hover: linear-gradient(90.19deg, #7233E8 -0.61%, #4DA0F5 99.46%);
$button-bg-gradient-active: linear-gradient(90.19deg, #6728DC -0.61%, #388CE2 99.46%);

$accent-color-1: #3DA2FF;
$accent-color-2: #8043F1;
$title-color: #070707;
$text-color-dark: #454545;
$text-color-light: #ffffff;

$section-bg-dark-color: #000005;
$footer-text-color: #6D6F75;

$header-z-index: 11;
$footer-z-index: 11;

$skew-deg: -2.8deg;

$common-bg-color-1: #f2f2fe;
$common-bg-color-2: #e7e8fd;

@mixin touch-screen {
  @media (hover: none) { @content; };
}

@mixin not-touch-screen {
  @media (hover: hover) { @content; };
}

@mixin mobile {
  @media (max-width: $MOBILE_ONLY) { @content; }
}

@mixin desktop {
  @media (min-width: $TABLET_PORTRAIT) { @content; }
}

@mixin wide-desktop {
  @media (min-width: $TABLET_LANDSCAPE) { @content; }
}

@mixin site-block {
  width: 100%;
  padding: 0 18px;
  max-width: 1080px;
  margin: 0 auto;

  @include desktop {
    padding: 0 30px;
  }
}

@mixin skew-section($z-index) {
  // $z-index needs to go down from the top to bottom elements
  z-index: $z-index;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: inherit;
    bottom: 0;
    z-index: -1;
    transform-origin: right bottom;
    transform: skewY($skew-deg);
  }
}

@mixin common-page-block {
  padding: 0 20px;
  @include desktop {
    max-width: 1100px;
    padding: 0 200px 0 0;
    margin: 0 auto;
  }
}
