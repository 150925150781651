@import "src/constants";

.slide {
  display: flex;
  width: 314px;
  height: 238px;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  background: linear-gradient(138.63deg, #63B6FF 0%, #7F43EF 70.98%);
  border-radius: 15px;
  color: $text-color-light;

  @include desktop() {
    width: 430px;
    height: 314px;
    border-radius: 20px;
  }

  .slide-content {
    margin: 30px 20px 10px;
    h2 {
      margin: 0 0 15px;
    }

    @include desktop() {
      margin: 55px 35px 10px 34px;
      h2 {
        margin: 0 0 25px;
      }
    }
  }

  .slide-header {
    font-style: normal;
    font-weight: 800;
    font-size: 22px;
    line-height: 30px;
    color: $text-color-light;

    @include desktop() {
      font-size: 28px;
      line-height: 30px;
    }
  }

  .text {
    font-family: 'OpenSans', 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: $text-mobile-small-font-size;
    line-height: $text-mobile-small-line-height;
    @include desktop() {
      font-size: 15px;
      line-height: $text-desktop-small-line-height;
    }
  }
}
