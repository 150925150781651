@import 'src/constants';

.terms-page {
  .general-items-section {
    background-color: $common-bg-color-2;
    @include skew-section(8);
  }

  .technology-section {
    background-color: $common-bg-color-1;
    @include skew-section(7);
  }

  .payment-terms-section {
    background-color: $common-bg-color-2;
    @include skew-section(6);
  }

  .data-protection-section {
    background-color: $common-bg-color-1;
    @include skew-section(5);
  }

  .additional-section {
    background-color: $common-bg-color-2;
  }
}
