@import "src/constants";

.benefits-section {
  padding: 10vw 0 2vw;

  background-color: $main-bg-color;

  h1 {
    max-width: 645px;
  }

  p {
    max-width: 527px;
  }

  @include skew-section(7);

  .container {
    @include site-block;
    min-height: 600px;
    overflow: hidden;
  }
}
