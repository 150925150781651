@import "src/constants";

.cookies-block {
  position: fixed;
  width: 100%;
  bottom: 0;
  padding: 10px 20px;
  background-color: rgba(255,255,255,.9);
  color: $text-color-dark;

  @include desktop {
    padding: 20px 80px;
  }

  z-index: 11;

  .button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    .button {
      font-size: 12px;
      height: 28px;
      line-height: 12px;
    }
  }

  p, a {
    font-size: 13px;
    padding: 0;
    margin: 0;
  }

  a {
    color: #9423DA;
    text-decoration: none;
    cursor: pointer;
  }

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 20px;
}